import React from "react";
import styled from "styled-components";
import { ListItem } from "./SkillsList";

const StyledListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 1.5em;
    align-items: center;
    justify-items: start;
    padding: 0em 1em 0em;
`;

const TechStackHeader = styled.h2`
    font-size: 2em;
    color: hsl(0, 0%, 20%);
    padding-top: 2.9em;
    padding-bottom: .5em;
`;

export default function TechStackList({ stack }) {
    return (
        <div>
            <TechStackHeader>Tech Stack</TechStackHeader>
            <StyledListContainer>
                {stack.map(stackItem => (
                    <ListItem>{stackItem}</ListItem>
                ))}
            </StyledListContainer>
        </div>
    );
}
