import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import homepage from "../../static/bugtracking_full_homepage.png";
import TechStackList from "../components/TechStackList";
import ProjectDetailsHeroSection from "../components/ProjectDetailsHeroSection";
import ProjectBackgroundSection from "../components/ProjectBackgroundSection";
import BugtrackingChallengesSection from "../components/BugtrackingChallengesSection";
import Footer from '../components/Footer'
import ContactForm from '../components/ContactForm'
import SectionHeader from "../components/SectionHeader";
import tickets from "../../static/tickets_dashboard.png";
import projects from "../../static/projects_dashboard.png";

const AnimatedLink = styled.a`
    color: hsl(0, 0%, 15%);
    font-weight: bold;
    font-size: 1em;
    display: inline;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    :hover {
        border-bottom: 4px solid hsl(153, 54%, 43%);
    }
    transition: border-bottom 0.35s ease 0s;
`;

const StyledImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 2px;
    box-shadow: 1px 2px 8px gray;
    margin-top: 5em;
`;

const LinksParagraph = styled(Paragraph)`
    font-size: 2em;
    line-height: 2;
    color: hsl(0, 0%, 55%);
`;

const techStack = [
    "React with Typescript",
    "Bulma CSS",
    "Django Rest Framework",
    "Google Firebase Authentication",
    "Docker",
    "Google Cloud Platform",
];
export default function bugtracking() {
    return (
        <>
            <Helmet>
                <title>Bugtracking.io - Project Details</title>
            </Helmet>
            <Layout>
                <ProjectDetailsHeroSection
                    img={homepage}
                    imgHeight={"797"}
                    imgWidth={"595"}
                >
                    <Paragraph top>
                        Bugtracking.io is a bug tracking platform featuring
                        user-managed teams and support for multiple projects. It
                        is a full stack web application using React on the front
                        end and a containerized API built with Django Rest
                        Framework on the back end, alongside Google Firebase for
                        user authentication.
                    </Paragraph>
                    <TechStackList stack={techStack} />
                    <LinksParagraph>
                        I have deprecated this project, but you can still check out the{" "}
                        <AnimatedLink
                            href="https://github.com/justin-thurman/bugtracking-api"
                            target="_blank"
                        >
                            back end repository
                        </AnimatedLink>{" "}
                        or the{" "}
                        <AnimatedLink
                            href="https://github.com/justin-thurman/bugtracker-react-v2"
                            target="_blank"
                        >
                            front end repository
                        </AnimatedLink>{" "}
                        on GitHub.
                    </LinksParagraph>
                </ProjectDetailsHeroSection>
                <ProjectBackgroundSection>
                    <Paragraph top>
                        As my first complete web application, this project
                        served as my training ground for both React and Django.
                        When I began working on it in June, 2020, I had never
                        written a single line of Javascript. In fact, the first
                        iteration of the site was written entirely in Django,
                        with no separation of the front end and the back end. I
                        learned a lot about MVC design patterns, but when I
                        finally deployed the site, it was obvious to me that it
                        was not built to modern standards. The user interface,
                        being powered entirely by static HTML generated from
                        Django templates, felt outdated.
                    </Paragraph>
                    <Paragraph>
                        Almost as soon as the first version was deployed, I
                        turned my attention towards front end technologies. I
                        dove into Javascript fundamentals and ultimately settled
                        on React as my framework of choice. I rewrote the Django
                        side of the application from the ground up into a
                        RESTful API and confronted the many challenges of
                        integrating React with an API.
                    </Paragraph>
                    <Paragraph>
                        It was well worth it in the end, and I was left with a
                        modern, full stack web application that I use to manage
                        all my ongoing projects – including work on
                        Bugtracking.io itself!
                    </Paragraph>
                </ProjectBackgroundSection>
                <StyledImage src={projects} />
                <BugtrackingChallengesSection />
                <StyledImage src={tickets} />
                <SectionHeader>Next Steps</SectionHeader>
                <Paragraph top>
                    The list of lessons I learned working on this project is
                    almost endless, but perhaps the most important lesson was
                    this: I love development. When I finished this project, I
                    couldn’t wait to start the next – and more than that, I
                    couldn’t wait to implement all the lessons I learned, to
                    design the next project from the ground up to be cleaner,
                    more robust, more maintainable. This process of growth and
                    continual improvement is why I code, and I hope it never
                    stops.
                </Paragraph>
            </Layout>
            <Footer>
                <ContactForm />
            </Footer>
        </>
    );
}
