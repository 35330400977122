import React from "react";
import styled from "styled-components";
import PageHeader from "./PageHeader";

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 90px;
    animation: slide-up ease 1s;
    @keyframes slide-up {
        0% {
            transform: translateY(30px);
            opacity: 1;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }
`;

const StyledImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 2px;
    box-shadow: 1px 2px 8px gray;
    margin-top: 5em;
    animation: fade ease 1s;
    @keyframes fade {
        0% {
            transform: translateY(0px);
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }
`;

export default function ProjectDetailsHeroSection({
    children,
    img,
    imgHeight,
    imgWidth,
}) {
    return (
        <StyledContainer>
            <div>
                <PageHeader>Bugtracking.io</PageHeader>
                {children}
            </div>
            <StyledImage src={img} height={imgHeight} width={imgWidth} />
        </StyledContainer>
    );
}
