import React from "react";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";

const StyledContainer = styled.div`
    padding-top: 3em;
`;

export default function ProjectBackgroundSection({ children }) {
    return (
        <StyledContainer>
            <SectionHeader>Project Background</SectionHeader>
            {children}
        </StyledContainer>
    );
}
